import { Routes, Route, Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";

import { DeprecatedNotaryDetail } from "common/details/meeting/notary_details/deprecated/deprecated_notary_detail";
import { DeprecatedWitness } from "common/details/meeting/witness_details/deprecated_index";
import { DeprecatedNotaryDetailsContainer } from "common/details/meeting/notary_details/deprecated/deprecated_index";
import { DeprecatedVideoContainer } from "common/details/meeting/videos/deprecated/deprecated_index";
import EncompassIntegration from "admin_portal/company/details/integrations/encompass";
import ReswareIntegration from "admin_portal/company/details/integrations/resware";
import AdobeIntegration from "admin_portal/company/details/integrations/adobe";
import SessionExpiredModal from "common/authorization/session_expired_modal";
import Login from "common/account/login";
import ResetPassword from "common/account/login/reset_password";
import AdminAnalytics from "admin_portal/analytics";
import VerifyEmail from "common/account/verify_email";
import Activation from "admin_portal/account/activation";
import AdminDashboard from "admin_portal/dashboard";
import AdminNotaryList from "admin_portal/notaries";
import AdminMeetingRequests from "admin_portal/meeting_request";
import AdminSettings from "admin_portal/settings";
import AdminSystemSettings from "admin_portal/system_settings";
import AdminSystemSettingsGroup from "admin_portal/system_settings/group";
import OrganizationFlagsWrapper from "admin_portal/organization_flags";
import OrganizationFlags from "admin_portal/organization_flags/all";
import { ReasonCodesWrapper, ProofReasonCodes } from "admin_portal/reason_codes";
import AdminUserDetails from "admin_portal/user/details";
import AnalyticsDetails from "admin_portal/analytics/details";
import AdminTools from "admin_portal/tools";
import AdminCompanyDetails from "admin_portal/company/details";
import AdminFinanceTools from "admin_portal/finance";
import AdminCompanyPanels from "admin_portal/company/panels/";
import AdminPanelInfo from "admin_portal/company/panels/panel_info";
import TierDetails from "admin_portal/finance/details";
import Video from "common/details/meeting/videos/meeting_video";
import InstructionDetails from "common/details/instructions";
import RiskDetails from "common/details/risk";
import ActivityLogs from "common/transactions/activity_logs";
import AdminCompanyList from "admin_portal/company/list";
import PointsOfContactDetails from "common/details/recipients/points_of_contact";
import AdminList from "admin_portal/admins";
import NewAdminModal from "admin_portal/admins/new";
import AdminDetailsModal from "admin_portal/admins/details";
import PriceBreakdownWithRefund from "common/transactions/price_breakdown/with_refund";
import AdminMortgageCompanyDetails from "admin_portal/company/mortgage_company_details";
import AdminCompanyDetailsWrapper from "admin_portal/company/details/wrapper";
import AdminCompanyDetailsPricingAndBilling from "admin_portal/company/details/pricing_and_billing";
import AdminCompanyDetailsPricingAndBillingPricing from "admin_portal/company/details/pricing_and_billing/pricing";
import AdminCompanyDetailsPricingAndBillingBilling from "admin_portal/company/details/pricing_and_billing/billing";
import AdminCompanyDetailsTransactions from "admin_portal/company/details/transactions";
import AdminCompanyDetailsAuditLogs from "admin_portal/company/details/audit_logs";
import AdminCompanyDetailsOrgFlags from "admin_portal/company/details/org_flags";
import AdminCompanyDetailsDataSecurity from "admin_portal/company/details/data_security";
import AdminSupportTicketLink from "admin_portal/support_ticket";
import CommunicationSettings from "admin_portal/company/details/communication_settings";
import CommunicationSettingsGlobalSettings from "admin_portal/company/details/communication_settings/global_settings";
import CommunicationSettingsSignerEmails from "admin_portal/company/details/communication_settings/signer_emails";
import CommunicationSettingsContactEmails from "admin_portal/company/details/communication_settings/contact_emails";
import CommunicationSettingsEmailEdit from "admin_portal/company/details/communication_settings/edit_email";
import TransactionDetailsContainer from "admin_portal/transactions/details";
import Signer from "common/details/recipients/signer";
import IdentityDetails from "common/details/identity";
import WitnessDetail from "common/details/meeting/witness_details/witness_detail";
import DetailsBundle from "common/details/bundle";
import { SummaryContainer } from "common/details/summary";
import History from "common/details/history";
import App from "common/app";
import AppFrame from "admin_portal/app_frame";
import Meeting from "admin_portal/meeting";
import AudioVideoGate from "common/meeting/audio_video_gate";
import { AdminRedirectChecker } from "common/authorization";
import Logger from "common/logger";
import { useIsAuthenticated, HardLoginRedirect } from "common/authentication";
import { makeUserManagementRoutes } from "common/organization/member_management/routing";
import { composeViewerWrappers, createViewerManager } from "util/viewer_wrapper";
import APP from "constants/applications";
import AdminExperiments from "admin_portal/experiments";
import AdminExperimentsDetails from "admin_portal/experiments/details";
import AdminRedirectToTransaction from "admin_portal/redirect_to_transaction";
import InternalTransactionNotes from "common/details/internal_notes";
import { useUserFeatureGatingSpecialization } from "common/feature_gating/specialization";
import { EmailUITypes } from "admin_portal/company/details/communication_settings/types";
import {
  QualityControlFromMeeting,
  QualityControlFromTransaction,
} from "admin_portal/quality_control";
import { SETTINGS_PATH } from "util/routes";
import AdminTasks from "admin_portal/tasks";
import { OrgStructurePage } from "admin_portal/company/details/org_structure";
import { RecipientColorProvider } from "common/pdf/recipient_colors/context";
import { useTxnDetailsRedesign } from "util/feature_detection";
import { RecipientsContainer } from "common/details/recipients";
import { MeetingContainer } from "common/details/meeting";
import { DomainVerification } from "common/organization/access/domains";
import { DomainPolicies } from "common/organization/access/domains/policies";
import { IdentityProviders } from "common/organization/access/identity_providers";
import { IdentityProviderConfiguration } from "common/organization/access/identity_providers/view_idp";
import { DeprecatedSummary } from "common/details/summary/deprecated/deprecated_index";
import ErrorBoundary from "common/error_boundary";

import { RedirectSlackLinkDetails } from "./redirect_old_slack_link_details";
import ViewerQuery, { type AdminRouterViewer_viewer as Viewer } from "./viewer_query.graphql";

const ENTRY = APP.ADMIN;
const ViewerManager = createViewerManager<Viewer>(ViewerQuery, [
  useUserFeatureGatingSpecialization,
]);
const DefaultWrapper = composeViewerWrappers<Viewer>(AdminRedirectChecker, AppFrame, Logger);
const AuthWrapper = composeViewerWrappers<Viewer>(RecipientColorProvider);

function TransactionDetailsRoutes({ isTxnDetailsRedesign }: { isTxnDetailsRedesign: boolean }) {
  return (
    <TransactionDetailsContainer>
      {(props) => (
        <Routes>
          <Route path="summary" element={<SummaryContainer {...props} entry={ENTRY} />} />
          <Route
            path="contacts"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../user" replace />
              ) : (
                <PointsOfContactDetails {...props} canEdit={false} />
              )
            }
          />
          {isTxnDetailsRedesign && (
            <Route path="meeting" element={<MeetingContainer {...props} />} />
          )}
          <Route path="user" element={<RecipientsContainer {...props} canEdit={false} />} />
          <Route path="identity" element={<IdentityDetails {...props} />} />
          <Route
            path="notary"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedNotaryDetailsContainer {...props} />
              )
            }
          />
          <Route
            path="witness"
            element={
              isTxnDetailsRedesign ? <Navigate to="../meeting" /> : <DeprecatedWitness {...props} />
            }
          />
          <Route path="deepfake" element={<RiskDetails {...props} />} />
          <Route path="document" element={<DetailsBundle {...props} />} />
          <Route
            path="video"
            element={
              isTxnDetailsRedesign ? (
                <Navigate to="../meeting" />
              ) : (
                <DeprecatedVideoContainer {...props} />
              )
            }
          />
          <Route path="history" element={<History {...props} />} />
          <Route path="activity" element={<ActivityLogs {...props} />} />
          <Route path="notes" element={<InstructionDetails {...props} isNotarizeAdmin />} />
          <Route path="internal_notes" element={<InternalTransactionNotes />} />
        </Routes>
      )}
    </TransactionDetailsContainer>
  );
}

const UNAUTH_ROUTES = (
  <>
    <Route path="/verify-account" element={<VerifyEmail />} />

    <Route path="/activate/:token" element={<Activation entry={ENTRY} />} />

    <Route path="/reset_password/:token" element={<ResetPassword />} />

    {/* this should be here for now even with consistent login
      because admins need a way to login even if another account
      is already logged in */}
    <Route path="/login" element={<Login entry={ENTRY} />} />

    <Route path="*" element={<HardLoginRedirect />} />
  </>
);

const authRoutes = (transactionDetailsRedesignFlag: boolean) => {
  return (
    <Route element={<AuthWrapper />}>
      <Route
        path="/dashboard"
        element={
          <DefaultWrapper>
            <AdminDashboard />
          </DefaultWrapper>
        }
      />

      <Route
        path="/notary"
        element={
          <DefaultWrapper>
            <AdminNotaryList />
          </DefaultWrapper>
        }
      />

      <Route
        path="/meeting-requests"
        element={
          <DefaultWrapper>
            <AdminMeetingRequests />
          </DefaultWrapper>
        }
      />

      <Route
        path="/tools"
        element={
          <DefaultWrapper>
            <AdminTools />
          </DefaultWrapper>
        }
      />

      <Route path="/tasks" element={<DefaultWrapper />}>
        <Route index element={<AdminTasks />} />
      </Route>

      <Route
        path="/meeting/:meetingId"
        element={
          <AudioVideoGate>
            <Meeting />
          </AudioVideoGate>
        }
      />

      <Route path="/finance" element={<DefaultWrapper />}>
        <Route index element={<AdminFinanceTools />} />
        <Route path="details/:id" element={<TierDetails />} />
        <Route path="new" element={<TierDetails />} />
      </Route>

      <Route path="/experiments" element={<DefaultWrapper />}>
        <Route index element={<AdminExperiments />} />
        <Route path=":id" element={<AdminExperimentsDetails />} />
      </Route>

      <Route
        path={SETTINGS_PATH}
        element={
          <DefaultWrapper>
            <AdminSettings />
          </DefaultWrapper>
        }
      />

      <Route
        path="/system-settings"
        element={
          <DefaultWrapper>
            <AdminSystemSettings />
          </DefaultWrapper>
        }
      >
        <Route path=":groupName" element={<AdminSystemSettingsGroup />} />
      </Route>

      <Route
        path="/organization-flags/"
        element={
          <DefaultWrapper>
            <OrganizationFlagsWrapper />
          </DefaultWrapper>
        }
      >
        <Route index element={<Navigate to="all" />} />
        <Route path="all" element={<OrganizationFlags />} />
      </Route>

      <Route
        path="/reason-codes/"
        element={
          <DefaultWrapper>
            <ReasonCodesWrapper />
          </DefaultWrapper>
        }
      >
        <Route index element={<Navigate to="proof" />} />
        <Route path="proof" element={<ProofReasonCodes />} />
      </Route>
      <Route
        path="/users/:userId"
        element={
          <DefaultWrapper>
            <AdminUserDetails />
          </DefaultWrapper>
        }
      />

      <Route path="/company/:globalID" element={<DefaultWrapper />}>
        <Route path="mortgage_details" element={<AdminMortgageCompanyDetails />} />
      </Route>

      <Route
        path="/redirect-to-transaction/:globalID"
        element={
          <DefaultWrapper>
            <AdminRedirectToTransaction />
          </DefaultWrapper>
        }
      />

      <Route path="/admin" element={<DefaultWrapper />}>
        <Route index element={<Navigate to="/admin/records?page=1" />} />
        <Route path="records" element={<AdminList />}>
          <Route path="new" element={<NewAdminModal />} />
          <Route path="admin/:globalID" element={<AdminDetailsModal />} />
        </Route>
      </Route>

      <Route
        path="/companies/page/:companiesPage"
        element={
          <DefaultWrapper>
            <AdminCompanyList />
          </DefaultWrapper>
        }
      />

      <Route path="/companies/:globalID">
        <Route index element={<Navigate to="structure" />} />

        <Route
          path="communication-settings/signer-emails/:emailName/edit"
          element={<CommunicationSettingsEmailEdit uiType={EmailUITypes.SIGNER} />}
        />

        <Route
          path="communication-settings/contact-emails/:emailName/edit"
          element={<CommunicationSettingsEmailEdit uiType={EmailUITypes.CONTACT} />}
        />

        <Route
          path="*"
          element={
            <DefaultWrapper>
              <AdminCompanyDetailsWrapper />
            </DefaultWrapper>
          }
        >
          <Route path="structure" element={<OrgStructurePage />} />

          <Route path="access">
            <Route path="domains">
              <Route index element={<DomainVerification />} />
              <Route path=":domain/policies" element={<DomainPolicies />} />
            </Route>
            <Route path="identity-providers">
              <Route index element={<IdentityProviders />} />
              <Route path=":idpId/configuration" element={<IdentityProviderConfiguration />} />
            </Route>
          </Route>

          <Route path="logs" element={<AdminCompanyDetailsAuditLogs />} />

          <Route path="flags" element={<AdminCompanyDetailsOrgFlags />} />

          <Route path="data-security" element={<AdminCompanyDetailsDataSecurity />} />

          <Route path="transactions" element={<AdminCompanyDetailsTransactions />}>
            {!transactionDetailsRedesignFlag && (
              <Route
                path=":transactionID/*"
                element={
                  <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
                }
              />
            )}
          </Route>

          {transactionDetailsRedesignFlag && (
            <Route
              path="transactions/:transactionID/*"
              element={
                <TransactionDetailsRoutes isTxnDetailsRedesign={transactionDetailsRedesignFlag} />
              }
            />
          )}

          <Route path="details" element={<AdminCompanyDetails />} />
          <Route path="panels" element={<AdminCompanyPanels />} />
          <Route path="panels/:panelId" element={<AdminPanelInfo />} />
          <Route path="integrations/encompass" element={<EncompassIntegration />} />
          <Route path="integrations/resware" element={<ReswareIntegration />} />
          <Route path="integrations/adobe" element={<AdobeIntegration />} />

          <Route
            path="communication-settings/*"
            element={
              <CommunicationSettings>
                {({ organization }) => (
                  <Routes>
                    <Route
                      path="global-settings"
                      element={<CommunicationSettingsGlobalSettings organization={organization} />}
                    />
                    <Route
                      path="signer-emails"
                      element={<CommunicationSettingsSignerEmails organization={organization} />}
                    />
                    <Route
                      path="contact-emails"
                      element={<CommunicationSettingsContactEmails organization={organization} />}
                    />
                    <Route index element={<Navigate to="global-settings" />} />
                  </Routes>
                )}
              </CommunicationSettings>
            }
          />

          <Route
            path="pricing-and-billing/*"
            element={
              <AdminCompanyDetailsPricingAndBilling>
                {({ organization }) => (
                  <Routes>
                    <Route
                      path="pricing"
                      element={
                        <AdminCompanyDetailsPricingAndBillingPricing organization={organization} />
                      }
                    />
                    <Route index element={<Navigate to="pricing" />} />
                    <Route
                      path="billing"
                      element={
                        <AdminCompanyDetailsPricingAndBillingBilling organization={organization} />
                      }
                    />
                  </Routes>
                )}
              </AdminCompanyDetailsPricingAndBilling>
            }
          />
        </Route>
        {makeUserManagementRoutes({
          wrapper: (
            <DefaultWrapper>
              <AdminCompanyDetailsWrapper />
            </DefaultWrapper>
          ),
        })}
      </Route>

      {/* Special route to support a link from zendesk chat (ETX-2278) */}
      <Route
        path="/support-ticket"
        element={
          <DefaultWrapper>
            <AdminSupportTicketLink />
          </DefaultWrapper>
        }
      />

      {/* Special route to support a link from Notabot slack message (MG-844); not wired in UI. */}
      <Route path="/transaction_details/:transactionId/*" element={<RedirectSlackLinkDetails />} />

      <Route
        path="/analytics"
        element={
          <DefaultWrapper>
            <AdminAnalytics />
          </DefaultWrapper>
        }
      >
        <Route path="page/:page" element={null} />
        <Route
          path="page/:page/:globalID/*"
          element={
            <AnalyticsDetails>
              {(props) => (
                <Routes>
                  <Route path="summary" element={<DeprecatedSummary {...props} />} />
                  <Route path="user" element={<Signer {...props} />} />
                  <Route
                    path="notary"
                    element={
                      <DeprecatedNotaryDetail
                        {...props}
                        onReclaim={props.refetch}
                        renderPriceBreakdown={(charge) => (
                          <PriceBreakdownWithRefund refetch={props.refetch} charge={charge} />
                        )}
                      />
                    }
                  />
                  <Route path="deepfake" element={<RiskDetails {...props} />} />
                  <Route path="witness" element={<WitnessDetail {...props} />} />
                  <Route path="video" element={<Video />} />
                  <Route path="document" element={<DetailsBundle {...props} />} />
                </Routes>
              )}
            </AnalyticsDetails>
          }
        />
      </Route>
      <Route path="/quality-control/notary/:notaryId" element={<DefaultWrapper />}>
        <Route path="meeting/:meetingId" element={<QualityControlFromMeeting />} />
        <Route path="transaction/:transactionId" element={<QualityControlFromTransaction />} />
      </Route>

      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Route>
  );
};

function AdminRouter() {
  const isAuthenticated = useIsAuthenticated();
  const isTxnDetailsRedesign = useTxnDetailsRedesign("keystone");
  return (
    <App isAuthenticated={isAuthenticated}>
      <Routes>
        <Route path="/session_expired" element={<SessionExpiredModal />} />
        {isAuthenticated ? authRoutes(isTxnDetailsRedesign) : UNAUTH_ROUTES}
      </Routes>
    </App>
  );
}

const Root = () => (
  <ErrorBoundary>
    <ViewerManager>
      <AdminRouter />
    </ViewerManager>
  </ErrorBoundary>
);

const router = createBrowserRouter([{ path: "*", element: <Root /> }]);

export default () => {
  return <RouterProvider router={router} />;
};
